import React from 'react';
import classNames from 'classnames';

import styles from './Loader.module.scss';

interface ILoaderProps {
  fullscreen?: boolean;
}

const Loader = ({
  fullscreen = false,
}: ILoaderProps) => {
  const rootClass = classNames(
    styles['m-loader'],
    fullscreen && styles['-fullscreen'],
  );

  return (
    <div className={rootClass} data-test="loader__image">
      <div className={`d-flex justify-content-center align-items-center ${styles['m-loader__container-outer']}`}>
        <div className={styles[`m-loader__container`]}>
          <div
            className={styles[`m-loader__spinner`]}
            role="status"
            aria-label="Loading..."
          />
          <img
            src={'https://s3.amazonaws.com/static.havenly.com/content/signup-and-login/LoadingAnimation.gif'}
            aria-hidden="true"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Loader;
